@import '../base/mixins';

.hero-container {
  @include flex-between-start(row);

  position: relative;
  width: 100%;

  .hero-blur {
    @include blur_orange;

    width: 22rem;
    height: 30rem;
    left: 0;
  }

  .left-hero {
    @include flex-start(column);

    padding: 1.5rem 2rem;
    width: 75%;
    gap: 2rem;

    .the-best-ad {
      @include flex-start(column);

      position: relative;
      margin-top: 3rem;
      background-color: $darkGray;
      border-radius: 3.5rem;
      width: fit-content;
      padding: 20px 13px;
      text-transform: uppercase;
      color: $white;

      span {
        z-index: 2;
      }

      .background {
        position: absolute;
        background-color: $orange;
        width: 5.4rem;
        height: 80%;
        left: 8px;
        border-radius: 3rem;
        z-index: 1;
      }
    }

    .hero-text {
      @include flex-start(column);

      text-transform: uppercase;
      font-size: 4rem;
      font-weight: bold;
      color: $white;
      text-overflow: inherit;

      .text-description {
        padding-top: 0.8rem;
        font-size: 1rem;
        font-weight: 200;
        text-transform: none;
        letter-spacing: 1px;
        width: 80%;
      }
    }

    .figures {
      @include flex-start(row);

      gap: 1rem;

      .block-fig {
        @include flex-center(column);

        & > span:nth-child(1) {
          color: $white;
          font-size: 1.3rem;
        }

        & > span:nth-child(2) {
          color: $gray;
          text-transform: uppercase;
        }
      }
    }

    .hero-buttons {
      display: flex;
      gap: 1rem;

      & > :nth-child(1) {
        color: $white;
        background-color: $orange;
      }

      & > :nth-child(2) {
        color: $white;
        background-color: transparent;
        border: 2px solid $orange;
      }
    }
  }

  .right-hero {
    position: relative;
    background-color: $orange;
    width: 25%;
    min-height: 100vh;

    & > .btn {
      position: absolute;
      right: 3rem;
      top: 2rem;
      color: $black;
    }

    .heart-rate {
      @include flex-center(column);

      background-color: $darkGray;
      gap: 0.7rem;
      width: fit-content;
      border-radius: 5px;
      position: absolute;
      right: 4rem;
      top: 7rem;
      padding: 0.5rem;
      font-size: 1rem;

      & > :nth-child(2) {
        color: $gray;
      }

      & > :nth-child(3) {
        color: $white;
        font-size: 1.2rem;
      }

      .love_img {
        width: 2rem;
      }
    }

    .hero_image {
      position: absolute;
      right: 8rem;
      top: 8rem;
      width: 22rem;
      height: 30rem;
    }

    .hero_image_back {
      position: absolute;
      top: 4rem;
      z-index: -1;
      width: 15rem;
      right: 20rem;
    }

    .calories {
      display: flex;
      gap: 1rem;
      background-color: $caloryCard;
      border-radius: 5px;
      width: fit-content;
      position: absolute;
      top: 33rem;
      right: 24rem;
      padding: 0.5rem;

      .calories_img {
        width: 3rem;
      }

      .calories_text {
        @include flex-between(column);

        // width: 50%;

        & > :nth-child(1) {
          color: $gray;
        }

        & > :nth-child(2) {
          color: $white;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .hero-container {
    @include flex-between-start(column);

    .hero-blur {
      width: 14rem;
    }

    .left-hero {
      width: 100%;
      gap: 1.7rem;

      .the-best-ad {
        margin-top: 1rem;
        font-size: small;
        align-self: center;
        transform: scale(0.9);

        span {
          text-align: center;
        }
      }

      .hero-text {
        font-size: xx-large;
        align-items: center;
        justify-content: center;

        .text-description {
          font-size: small;
          font-weight: 200;
          width: 80%;
          text-align: center;
          letter-spacing: 1px;
        }
      }

      .hero-buttons {
        @include flex-center(row);

        width: 100%;
      }

      .figures {
        @include flex-center(row);

        width: 100%;

        .block-fig {
          & > span:nth-child(1) {
            font-size: large;
          }

          & > span:nth-child(2) {
            font-size: smaller;
            text-align: center;
          }
        }
      }
    }

    .right-hero {
      @include flex-center(column);

      position: relative;
      background: none;
      width: 100%;

      & > .btn {
        right: 3.4rem;
      }

      .heart-rate {
        top: 2rem;
        left: 1rem;
      }

      .hero_image {
        position: relative;
        width: 14rem;
        height: 20rem;
        top: 4.5rem;
        left: 6rem;
        align-self: center;
      }

      .hero_image_back {
        width: 15rem;
        top: 0;
        left: 2rem;
      }

      .calories {
        position: relative;
        top: 5rem;
        left: 2rem;

        .calories_img {
          width: 2rem;
        }

        .calories_text {
          & > :nth-child(1) {
            font-size: small;
          }

          &:nth-child(2) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
