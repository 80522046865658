$lightgray: #d9d9d9;
$gray: #9c9c9c;
$orange: #f48915;
$darkOrange: #754008ee;
$darkGrey: #464d53;
$caloryCard: #656565;
$planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
$appColor: #3c3f45;
$white: #fff;
$darkGray: #363d42;
$black: #000;
