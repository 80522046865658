@import '../base/mixins';
@import '../base/colors';

.Programs {
  @include flex-column;

  padding: 0 2rem;
  gap: 2rem;

  &-header {
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-style: italic;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .programs-categories {
    @include flex-center(row);

    gap: 1rem;

    .category {
      flex-wrap: wrap;

      .card {
        @include flex-column;

        gap: 1rem;
        background-color: $gray;
        color: $white;
        justify-content: space-between;
        min-height: 300px;
        max-height: 300px;
        border: solid white;
        padding: 0.7rem;
        text-align: justify;

        & > :nth-child(1) {
          width: 2rem;
          height: 2rem;
          fill: $white;
        }

        & > :nth-child(2) {
          font-size: 1rem;
          font-weight: bold;
        }

        & > :nth-child(3) {
          font-size: 0.9rem;
          line-height: 25px;
        }

        .join-now {
          display: flex;
          align-items: center;
          gap: 2rem;
          background: none;
          outline: none;
          border: none;
          cursor: pointer;
          color: $white;
          font-size: 1rem;
          font-weight: bold;

          img { width: 1rem; }
        }

        &:hover {
          background: $planCard;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Programs {
    &-header {
      @include flex-center(column);

      font-size: x-large;
    }

    .programs-categories {
      @include flex-center(column);

      .category {
        min-width: 12rem;
        max-width: 19rem;
      }
    }
  }
}
