@mixin flex-center($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

@mixin flex-center-start($direction) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: $direction;
}

@mixin flex-start-center($direction) {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: $direction;
}

@mixin flex-start($direction) {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: $direction;
}

@mixin flex-start-top($direction) {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: $direction;
}

@mixin flex-between($direction) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $direction;
}

@mixin flex-between-start($direction) {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: $direction;
}

@mixin flex-center-end($direction) {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: $direction;
}

@mixin flex-evenly($direction) {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: $direction;
}

@mixin buttons {
  background-color: transparent;
  border: 1px solid $dark-color;
  padding: 10px;
  box-shadow: 3px 3px 3px $dark-color;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin blur_orange {
  background: rgba(252, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(212px);
  z-index: -9;
}
