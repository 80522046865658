@import '../base/_mixins';
@import '../base/colors';

.reasons {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;

  .left-reasons {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;

    img {
      object-fit: cover;
    }

    & > :nth-child(1) {
      width: 12rem;
      grid-row: 1/3;
      height: 28rem;
    }

    & > :nth-child(2) {
      width: auto;
      height: 16rem;
      grid-column: 2/4;
    }

    & > :nth-child(3) {
      width: 14rem;
      grid-column: 2/3;
      grid-row: 2;
    }

    & > :nth-child(4) {
      width: 10rem;
      grid-row: 2;
      grid-column: 3/4;
      height: 11.2rem;
    }
  }

  .right-reasons {
    @include flex-column;

    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;

    .heading {
      font-weight: bold;
      color: $orange;
    }

    .text_container {
      color: $white;
      font-size: 3rem;
      font-weight: bold;
    }

    .details-right {
      @include flex-column;

      gap: 1rem;

      .detail-item {
        @include flex-center-start(row);

        font-size: 1rem;
        gap: 0.8rem;
        color: $white;
        font-weight: normal;

        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .our-partners-container {
      .our-partners-title {
        color: $gray;
        font-weight: normal;
      }

      .partners-list {
        display: flex;
        gap: 1rem;
        margin-top: 0.7rem;

        & li img {
          width: 2.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .reasons {
    @include flex-center(column-reverse);

    .left-reasons {
      // width: 70%;
      grid-auto-rows: auto;
      overflow: hidden;
      justify-self: center;
      align-self: center;

      & > :nth-child(1) {
        width: 10rem;
        height: 22rem;
      }

      & > :nth-child(2) {
        width: 19.5rem;
        height: 10rem;
      }

      & > :nth-child(3) {
        width: 9rem;
        height: 11rem;
      }

      & > :nth-child(4) {
        width: 9rem;
        height: 11rem;
      }
    }
  }
}

@media screen and (max-width: 623px) {
  .reasons {
    flex-direction: column-reverse;

    .left-reasons {
      grid-auto-rows: auto;
      overflow: hidden;
      justify-content: center;
      gap: 0.6rem;

      & > :nth-child(1) {
        width: 7rem;
        height: 17rem;
      }

      & > :nth-child(2) {
        width: 15rem;
        height: 10rem;
      }

      & > :nth-child(3) {
        height: 6rem;
        width: 7rem;
      }

      & > :nth-child(4) {
        width: 7rem;
        height: 6rem;
      }
    }

    .right-reasons {
      .text_container {
        color: $white;
        font-size: x-large;
        font-weight: bold;
      }
    }
  }
}
